.hover-preview {
    animation: slideInUp 0.5s;
    animation-direction: normal;
}


.preview-image {
    -o-object-fit: cover;
    object-fit: cover;
}



@keyframes slideInUp {
    from {
        opacity: 0;
        margin-top: 5rem;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}