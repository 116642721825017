/* General styles */
/* GENERAL */
html, body {
  max-width: 100vw;
}

body {
  transition: all 0.5s ease-in-out;
  font-family: 'Open Sans', sans-serif;
}

.bg-main {
  background-color: #FFFFFF;
}

.bg-secondary {
  background-color: #FEFEFE;
}

body.dark-mode, body.dark-mode .bg-main {
  background-color: #17181C;
  color: #FFFFFF;
}
body.dark-mode .bg-secondary {
  background-color: #232631;
  color: #FFFFFF;
}

body.dark-mode .whiteInDarkMode {
  color: #FFFFFF;
}

* {
  max-width: 100vw;
}

/* INPUTS */
body.dark-mode input {
  color: #FFFFFF;
}

/* INPUT PLACEHOLDER */
body.light-mode input::-webkit-input-placeholder {
  color: #e0e0e0;
  opacity: 1;
}

body.light-mode input:-ms-input-placeholder {
  color: #e0e0e0;
  opacity: 1;
}

body.light-mode input::-ms-input-placeholder {
  color: #e0e0e0;
  opacity: 1;
}

body.light-mode input::placeholder {
  color: #e0e0e0;
  opacity: 1;
}

body.dark-mode input::-webkit-input-placeholder {
  color: #565962;
  opacity: 1;
}

body.dark-mode input:-ms-input-placeholder {
  color: #565962;
  opacity: 1;
}

body.dark-mode input::-ms-input-placeholder {
  color: #565962;
  opacity: 1;
}

body.dark-mode input::placeholder {
  color: #565962;
  opacity: 1;
}

/* NAV AND FOOTER */
nav a, .footer a {
  color: inherit;
}
.footer {
  bottom: 0;
}

/* VARIOUS */
body.light-mode .shadow {
  -webkit-box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
body.dark-mode .shadow {
  -webkit-box-shadow: 0 8px 30px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
          box-shadow: 0 8px 30px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
}

.bg-transparent {
  background-color: transparent;
}

.text-2xs {
  font-size: 0.65rem;
}

.color-inherit {
  color: inherit;
}

.outline-none:focus, .outline-none:hover, .outline-none:active {
  outline: 0;
}
.border-none:focus, .border-none:hover, .border-none:active {
  border-style: none;
}